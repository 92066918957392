import React, { useEffect, useRef, useState } from "react";
import PropTypes, { string } from "prop-types";
import { useSave, useSet, useQuery, useDetail } from "seed/gql";
import { SAVE_RELATIONSHIP, SAVE_RESPONSE } from "seed/gql/queries";
import View from "components/forms/ConflictOfInterest.view";
import { useGet, usePost } from "seed/api";
import { Loading } from "seed/helpers";
import { RESPONSE, SET_RESPONSE } from "seed/gql/queries";


function ConflictOfInterest({onCompleted = () => null, onError = () => null, history}) {
  const qUsers = useGet("/users", {});
  const qDepartments = useGet("/departments", {});
  const qCities = useGet("/cities", {});

  const [previousQ3Individuals, setPreviousQ3Individuals] = useState([]);
  const [previousQ4Individuals, setPreviousQ4Individuals] = useState([]);
  const [previousQ5Individuals, setPreviousQ5Individuals] = useState([]);
  const [previousQ6Individuals, setPreviousQ6Individuals] = useState([]);
  const [previousQ7Individuals, setPreviousQ7Individuals] = useState([]);

  const [q3Individuals, setQ3Individuals] = useState([]);
  const [q4Individuals, setQ4Individuals] = useState([]);
  const [q5Individuals, setQ5Individuals] = useState([]);
  const [q6Individuals, setQ6Individuals] = useState([]);
  const [q7Individuals, setQ7Individuals] = useState([]);

  const [isFirstResponse, setIsFirstResponse] = useState(false);

  const userId = sessionStorage.getItem("id");

  if (userId == null) {
    window.location.replace("/logout");
  }

  const [previousResponse, setPreviousResponse] = useState({});
  const [modifiedResponse, setModifiedResponse] = useState({});

  const qPrevResponses = useGet("/users/" + userId + "/get_last_response", {}, {
    onCompleted: (prevResponse) => {
      if ("is_first_response" in prevResponse) {
        setIsFirstResponse(prevResponse.is_first_response)
      }
      let temp = prevResponse.last_response;
      temp["q1"] = {"id": temp["q1_id"]}
      temp["q2"] = {"id": temp["q2_id"]}
      temp["q8"] = ""
      setPreviousResponse(temp)
      setPreviousQ3Individuals(prevResponse.relationships.q3_relationships);
      setPreviousQ4Individuals(prevResponse.relationships.q4_relationships);
      setPreviousQ5Individuals(prevResponse.relationships.q5_relationships);
      setPreviousQ6Individuals(prevResponse.relationships.q6_relationships);
      setPreviousQ7Individuals(prevResponse.relationships.q7_relationships);
    }
  });

  const qResponses = useGet("/users/" + userId + "/get_last_response", {}, {
    onCompleted: (response) => {
      let temp = response.last_response;
      temp["q1"] = {"id": temp["q1_id"]}
      temp["q2"] = {"id": temp["q2_id"]}
      temp["q8"] = ""
      setModifiedResponse(temp);
      setQ3Individuals(response.relationships.q3_relationships);
      setQ4Individuals(response.relationships.q4_relationships);
      setQ5Individuals(response.relationships.q5_relationships);
      setQ6Individuals(response.relationships.q6_relationships);
      setQ7Individuals(response.relationships.q7_relationships);
    }
  });
  

  const [callSaveResponse, qSaveResponse] = useSave(SAVE_RESPONSE, {
    onCompleted: (data) => {
      
      let relationships = {
        "q3_relationships": q3Individuals,
        "q4_relationships": q4Individuals,
        "q5_relationships": q5Individuals,
        "q6_relationships": q6Individuals,
        "q7_relationships": q7Individuals
      }

      callSaveRelationships({
        response_id: data["saveResponse"]["response"]["id"],
        relationships: relationships
      })
    }
  });

  const [callSaveRelationships, qSaveRelationships] = usePost("/relationships/save_relationships", {
    onCompleted: (data) => {
      history.push("/form/confirmation")
    }
  });
  

  if (qUsers.loading || qDepartments.loading || qCities.loading || qResponses.loading || 
    qSaveResponse.loading || qSaveRelationships.loading) 
    return <main id="content">
      <div
        class="position-fixed top-0 right-0 left-0 bg-img-hero"
        style={{ height: "32rem", marginRight: "20px", backgroundImage: "url(/theme/svg/components/abstract-bg-4.svg)"}}
      ></div>
      <div class="container py-10 py-sm-7" style={{height: "30rem"}}>
      <Loading color="#fff" class="mt-10" />
      </div>
    </main>
    
  if (qUsers.error || qDepartments.error || qCities.error || qResponses.error || 
    qSaveResponse.error || qSaveRelationships.loading) 
    return "Ha ocurrido un error, prueba de nuevo"


  const departments = qDepartments.data;
  const cities = qCities.data;
  const users = qUsers.data;
  const user = users.find(ele => ele["id"] == userId);
  const error = qSaveResponse.error ? "Ha ocurrido un error al cargar tu información, prueba más tarde" : null;

  let originalResponse = qResponses.data.last_response ? qResponses.data.last_response : {};

  originalResponse["q1"] = {"id": originalResponse["q1_id"]}
  originalResponse["q2"] = {"id": originalResponse["q2_id"]}
  
  let currentYear = new Date().getFullYear();
  const date = new Date();

  const getQIndividuals = (q) => {
    switch (q) {
      case "q3":
        return q3Individuals;
      
      case "q4":
        return q4Individuals;
      
      case "q5":
        return q5Individuals;
      
      case "q6":
        return q6Individuals;
      
      case "q7":
        return q7Individuals;
      
      default:
        break;
    }
  }

  const getsetQIndividuals = (q) => {
    switch (q) {
      case "q3":
        return setQ3Individuals;
        
      case "q4":
        return setQ4Individuals;

      case "q5":
        return setQ5Individuals;

      case "q6":
        return setQ6Individuals;

      case "q7":
        return setQ7Individuals;
    
      default:
        break;
    }
  }

  const addIndividual = (q) => {
    let qIndividuals = getQIndividuals(q);
    let setQIndividuals = getsetQIndividuals(q);


    setQIndividuals([
      ...qIndividuals,
      {
        entity_with_conflict: "",
        name_of_familiar: "",
        relationship_of_familiar: "",
        position_of_familiar: ""
      },
    ]);
  
  };

  const removeIndividual = (q, index) => {
    let temp = [];

    let qIndividuals = getQIndividuals(q);
    let setQIndividuals = getsetQIndividuals(q);


    for (let i = 0; i < qIndividuals.length; i++) {
      if (i != index) {
        temp.push(qIndividuals[i])
      }
    }

    setQIndividuals(temp);
  
  };

  const onChangeTextField = (q, index, key, value) => {
    let qIndividuals = getQIndividuals(q);
    let setQIndividuals = getsetQIndividuals(q);

    let temp = [...qIndividuals]

    temp[index][key] = value;
    
    setQIndividuals(temp);

  } 

  const onChangeSelect = (event) => {
    let temp = Object.assign({}, modifiedResponse)

    temp[event.target.name] = event.target.value;

    if (event.target.name == "q1.id" || event.target.name == "q2.id") {
      temp[event.target.name.split(".")[0]][event.target.name.split(".")[1]] = parseInt(event.target.value);

    }

    setModifiedResponse(temp);

  }

  const getColor = (q, index, key) => {
    let previuousElements;

    switch (q) {
      case "q1":
        if (!isFirstResponse && originalResponse[q]["id"] != modifiedResponse[q]["id"]) {
          return "#FAD070"
        
        } else {
          return ""
        }

      case "q2":
        if (!isFirstResponse && originalResponse[q]["id"] != modifiedResponse[q]["id"]) {
          return "#FAD070"
        
        } else {
          return ""
        }

      case "q8":
        if (!isFirstResponse && originalResponse[q] != modifiedResponse[q]) {
          return "#FAD070"
        
        } else {
          return ""
        }
        
      case "q3":
        previuousElements = previousQ3Individuals;
        break;

      case "q4":
        previuousElements = previousQ4Individuals;
        break;
      
      case "q5":
        previuousElements = previousQ5Individuals;
        break;

      case "q6":
        previuousElements = previousQ6Individuals;
        break;
      
      case "q7":
        previuousElements = previousQ7Individuals;
        break;

      default:
        break;
    }

    let currentElements = getQIndividuals(q);

    const currentElement = currentElements[index];

    let foundElement = false;

    for (let i = 0; i < previuousElements.length; i++) {
      const previuousElement = previuousElements[i];

      if (previuousElement.name_of_familiar == currentElement.name_of_familiar) {
        foundElement = true;
        if (previuousElement[key] != currentElement[key]) {
          return "#FAD070"
        }
      }
      
    }

    if (!foundElement && !isFirstResponse) {
      return "#FAD070"

    } else {
      return "";
    
    }
    
  }

  const onSubmit = () => {
    let temp = Object.assign({}, modifiedResponse);
    temp.year = currentYear;
    temp.status = "DRAFT";
    temp.user = parseInt(userId);
    let q1 = temp["q1"]["id"];
    let q2 = temp["q2"]["id"];
    delete temp["q1"];
    delete temp["q2"];
    temp.q1 = q1;
    temp.q2 = q2;

    callSaveResponse(temp);
  }

  const validateIndividuals = () => {
    const textKeys = [
      "entity_with_conflict", 
      "name_of_familiar",
      "position_of_familiar",
      "relationship_of_familiar",
      "organization_of_familiar"
    ]

    let validatedIndividuals = true;

    for (let n = 3; n <= 7; n++) {
      let qN = "q" + n;

      const qIndividuals = getQIndividuals(qN);

      for (let i = 0; i < qIndividuals.length; i++) {
        const individual = qIndividuals[i];
        
        for (let i = 0; i < textKeys.length; i++) {
          const key = textKeys[i];
          
          validatedIndividuals = validatedIndividuals && individual[key].length > 0;
        }
        
      }
      
    }

    return validatedIndividuals;
  }

  return <View
    originalResponse={originalResponse}
    modifiedResponse={modifiedResponse}
    departments={departments}
    cities={cities}
    users={users}
    user={user}
    error={error}
    currentYear={currentYear}
    q3Individuals={q3Individuals}
    q4Individuals={q4Individuals}
    q5Individuals={q5Individuals}
    q6Individuals={q6Individuals}
    q7Individuals={q7Individuals}
    addIndividual={addIndividual}
    removeIndividual={removeIndividual}
    onChangeTextField={onChangeTextField}
    onChangeSelect={onChangeSelect}
    getColor={getColor}
    onSubmit={onSubmit}
  />;
  
}

ConflictOfInterest.propTypes = {
  onCompleted: PropTypes.func,
  onError: PropTypes.func,
  history: PropTypes.object
};

export default ConflictOfInterest;