import React from "react";
import PropTypes from "prop-types";

const CompletedResponse = () =>
  <main id="content" role="main" class="main">
    <div
      class="position-fixed top-0 right-0 left-0 bg-img-hero"
      style={{
        height: "32rem",
        backgroundImage: "url(/theme/svg/components/abstract-bg-4.svg)",
      }}
    >
      <figure class="position-absolute right-0 bottom-0 left-0">
        <svg
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1921 273"
        >
          <polygon fill="#00454d" points="0,273 1921,273 1921,0 " />
        </svg>
      </figure>
    </div>

    <div class="container py-5 py-sm-7">
      <a class="d-flex justify-content-center mb-5" href="index.html">
        <img
          class="z-index-2"
          src="/theme/svg/logos/logoCrz.png"
          alt="Logo"
          style={{ width: "8rem" }}
        />
      </a>

      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="card card-lg pb-3">
            <div class="card-body">
              <div class="row justify-content-center">
                <div class="col-10 mt-3 text-center">
                  <h3 class="card-title pb-3">Formulario de conflicto de interés completado</h3>
                  <p class="card-text">
                    Ya has respondido el formulario de conflicto de interés correspondiente a este año
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>

CompletedResponse.propTypes = {};

export default CompletedResponse;