import React from "react";
import PropTypes from "prop-types";
import View from "components/utils/CompletedResponse.view";

function CompletedResponse() {
  return <View />;
}

CompletedResponse.propTypes = {};

export default CompletedResponse;