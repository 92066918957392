import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import css from "resources/css/App.module.css";
import ConflictOfInterest from "components/forms/ConflictOfInterest";
import Login from "components/auth/Login";
import Analytics from "components/analytics/Analytics";
import LoginAuth from "components/auth/LoginAuth";
import Logout from "components/auth/Logout";
import CompletedResponse from "components/utils/CompletedResponse";

const App = () =>
  <div class={css.module}>
    <BrowserRouter>
      <Switch>
        <Route path="/form" component={ConflictOfInterest} />
        <Route path="/login" component={Login} />
        <Route path="/auth" component={LoginAuth} />
        <Route path="/logout" component={Logout} />
        <Route path="/completed_response" component={CompletedResponse} />
        <Route path="/analytics" component={Analytics} />
        <Redirect to="/form" />
      </Switch>
    </BrowserRouter>
  </div>;

App.propTypes = {};

export default App;