/*
__Seed builder__
  AUTO_GENERATED (Read only)
  Modify via builder
*/

export const APP_INFO = `
{
  appInfo {
    id
    enableNotifications
  }
}
`;

export const SET_APP_INFO = `
mutation Set(
  $id: Int!,
  $enableNotifications: Boolean,
)
{
  setAppInfo(
    id: $id,
    enableNotifications: $enableNotifications,
  ) {
    appInfo {
      id
      enableNotifications
    }
  }
}
`;

export const SAVE_APP_INFO = `
mutation Save(
  $enableNotifications: Boolean!,
)
{
  saveAppInfo(
    enableNotifications: $enableNotifications,
  ) {
    appInfo {
      id
    }
  }
}
`;

export const DELETE_APP_INFO = `
mutation Delete($id: Int!)
{
  deleteAppInfo(id: $id)
  {
    id
  }
}
`;

export const CITY = `
{
  city {
    id
    name
  }
}
`;

export const SET_CITY = `
mutation Set(
  $id: Int!,
  $name: String,
)
{
  setCity(
    id: $id,
    name: $name,
  ) {
    city {
      id
      name
    }
  }
}
`;

export const SAVE_CITY = `
mutation Save(
  $name: String!,
)
{
  saveCity(
    name: $name,
  ) {
    city {
      id
    }
  }
}
`;

export const DELETE_CITY = `
mutation Delete($id: Int!)
{
  deleteCity(id: $id)
  {
    id
  }
}
`;

export const DEPARTMENT = `
{
  department {
    id
    name
  }
}
`;

export const SET_DEPARTMENT = `
mutation Set(
  $id: Int!,
  $name: String,
)
{
  setDepartment(
    id: $id,
    name: $name,
  ) {
    department {
      id
      name
    }
  }
}
`;

export const SAVE_DEPARTMENT = `
mutation Save(
  $name: String!,
)
{
  saveDepartment(
    name: $name,
  ) {
    department {
      id
    }
  }
}
`;

export const DELETE_DEPARTMENT = `
mutation Delete($id: Int!)
{
  deleteDepartment(id: $id)
  {
    id
  }
}
`;

export const RELATIONSHIP = `
{
  relationship {
    id
    entityWithConflict
    nameOfFamiliar
    positionOfFamiliar
    relationshipOfFamiliar
    q
    response {
      id
    }
  }
}
`;

export const SET_RELATIONSHIP = `
mutation Set(
  $id: Int!,
  $entityWithConflict: String,
  $nameOfFamiliar: String,
  $positionOfFamiliar: String,
  $relationshipOfFamiliar: String,
  $q: Int,
  $response: Int,
)
{
  setRelationship(
    id: $id,
    entityWithConflict: $entityWithConflict,
    nameOfFamiliar: $nameOfFamiliar,
    positionOfFamiliar: $positionOfFamiliar,
    relationshipOfFamiliar: $relationshipOfFamiliar,
    response: $response,
    q: $q,
  ) {
    relationship {
      id
      entityWithConflict
      nameOfFamiliar
      positionOfFamiliar
      relationshipOfFamiliar
      q
      response {
        id
      }
    }
  }
}
`;

export const SAVE_RELATIONSHIP = `
mutation Save(
  $entityWithConflict: String!,
  $nameOfFamiliar: String!,
  $positionOfFamiliar: String!,
  $relationshipOfFamiliar: String!,
  $q: Int!,
  $response: Int,
)
{
  saveRelationship(
    entityWithConflict: $entityWithConflict,
    nameOfFamiliar: $nameOfFamiliar,
    positionOfFamiliar: $positionOfFamiliar,
    relationshipOfFamiliar: $relationshipOfFamiliar,
    response: $response,
    q: $q,
  ) {
    relationship {
      id
    }
  }
}
`;

export const DELETE_RELATIONSHIP = `
mutation Delete($id: Int!)
{
  deleteRelationship(id: $id)
  {
    id
  }
}
`;

export const RESPONSE = `
{
  response {
    id
    year
    q8
    status
    q1 {
      id
    }
    q2 {
      id
    }
    user {
      id
    }
  }
}
`;

export const SET_RESPONSE = `
mutation Set(
  $id: Int!,
  $year: Int,
  $q8: String,
  $status: String,
  $q1: Int,
  $q2: Int,
  $user: Int,
)
{
  setResponse(
    id: $id,
    year: $year,
    q1: $q1,
    q2: $q2,
    q8: $q8,
    user: $user,
    status: $status,
  ) {
    response {
      id
      year
      q8
      status
      q1 {
        id
      }
      q2 {
        id
      }
      user {
        id
      }
    }
  }
}
`;

export const SAVE_RESPONSE = `
mutation Save(
  $year: Int!,
  $q8: String!,
  $status: String!,
  $q1: Int!,
  $q2: Int!,
  $user: Int!,
)
{
  saveResponse(
    year: $year,
    q1: $q1,
    q2: $q2,
    q8: $q8,
    user: $user,
    status: $status,
  ) {
    response {
      id
    }
  }
}
`;

export const DELETE_RESPONSE = `
mutation Delete($id: Int!)
{
  deleteResponse(id: $id)
  {
    id
  }
}
`;

export const USER = `
{
  user {
    id
    username
    firstName
    lastName
    email
    isActive
    rol
    area
  }
}
`;

export const SET_USER = `
mutation Set(
  $id: Int!,
  $username: String,
  $firstName: String,
  $lastName: String,
  $email: String,
  $isActive: Boolean,
  $password: String,
  $rol: String,
  $area: String,
)
{
  setUser(
    id: $id,
    username: $username,
    firstName: $firstName,
    lastName: $lastName,
    email: $email,
    isActive: $isActive,
    password: $password,
    rol: $rol,
    area: $area,
  ) {
    user {
      id
      username
      firstName
      lastName
      email
      isActive
      rol
      area
    }
  }
}
`;

export const SAVE_USER = `
mutation Save(
  $username: String!,
  $firstName: String!,
  $lastName: String!,
  $email: String!,
  $isActive: Boolean!,
  $password: String!,
  $rol: String!,
  $area: String!,
)
{
  saveUser(
    username: $username,
    firstName: $firstName,
    lastName: $lastName,
    email: $email,
    isActive: $isActive,
    password: $password,
    rol: $rol,
    area: $area,
  ) {
    user {
      id
    }
  }
}
`;

export const DELETE_USER = `
mutation Delete($id: Int!)
{
  deleteUser(id: $id)
  {
    id
  }
}
`;