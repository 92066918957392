import React, { useEffect, useState } from "react";
import {usePost, useGetCall} from "seed/api"
import {API_URL} from "settings"
import PropTypes from "prop-types";
import View from "components/analytics/Analytics.view";
import { Loading } from "seed/helpers";

function Analytics(props) {
  const [isAuth, setIsAuth] = useState(false) 
  const [callAuth, reqCall] = useGetCall("/auth/user", "", {
    onCompleted: data => {
      const isAdmin = data.username == "erick.rivas@chevez.com.mx" || data.username == "igonzalez@chevez.com.mx" 
      if (isAdmin) setIsAuth(true);
      else props.history.replace("/logout")
    },
    onError: () => {
      sessionStorage.setItem("loginRedirect", "/analytics");
      setTimeout(() => props.history.replace("/login"), 1000)
    }
  })

  const [callExport, qExport] = usePost("/responses/export", {
    onCompleted: data => {
      if (data == "async") 
        alert("Procesando...\nDebido al volumen de información, te enviaremos un correo en cuanto esté listo el reporte")
      else window.location.href = data
    }
  })

  useEffect(() => {
    callAuth();    
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isAuth) return <div className="pt-10"><Loading /></div>;

  const onClickExcel = () => {
    let email = window.prompt("Correo de usuario (Dejar vacío para descargar toda la información)")
    if (email){
      if (email.includes("@"))
        callExport({
          code: "CRZ2023",
          email: email,
          year: "N/A"
        })
      else
        alert("Correo inválido")
    }
    else {
      if (email == ""){
        let year = window.prompt("Selecciona un año");
        if (year){
          if (year.startsWith("20"))
          callExport({
            code: "CRZ2023",
            email: "",
            year: year
          })
          else
            alert("Año inválido")
        }
      }
    }
  }

  if (qExport.error) return "Ha ocurrido un error exportando la información"


  return <View
  loading={qExport.loading}
  onClickExcel={onClickExcel}
  />;
}

Analytics.propTypes = {};

export default Analytics;