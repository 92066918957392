import React from "react";
import {SERVER_URL} from "settings"
import { Loading } from "seed/helpers";
import PropTypes from "prop-types";

const AnalyticsView = ({loading, onClickExcel}) =>
  <main id="content">
    <div
      class="position-fixed top-0 right-0 left-0 bg-img-hero"
      style={{
        height: "32rem",
        marginRight: "20px",
        backgroundImage: "url(/theme/svg/components/abstract-bg-4.svg)",
      }}
    >
      <figure class="position-absolute right-0 bottom-0 left-0">
        <svg
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1921 273"
        >
          <polygon fill="#00454d" points="0,273 1921,273 1921,0 " />
        </svg>
      </figure>
    </div>

    <div class="container py-5 py-sm-7">
      <a class="d-flex justify-content-center mb-5" href="index.html">
        <h1
          class="mt-2 display-4"
          style={{
            position: "absolute",
            zIndex: "3",
            color: "white",
            marginRight: "22.5rem",
          }}
        >
          Conflicto de Interés
        </h1>
        <img
          class="z-index-2 ml-10"
          src="/theme/svg/logos/logoCrz.png"
          alt="Logo"
          style={{ width: "8rem" }}
        />
      
      </a>

      <a href={SERVER_URL + "/admin/"} class="btn btn-light" style={{position: "absolute", top: "10px", right: "145px"}}>Administrador</a>
      <a href="#" onClick={onClickExcel} class="btn btn-light" style={{position: "absolute", top: "10px", right: "10px"}}>Exportar excel</a>
      


      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="card card-lg mb-5">
            <div class="card-body py-3 px-4">
              { loading ? <Loading/> : null}
              <iframe
                style={{
                  width: "100%", border: "none", overflow: "hidden", background: "#FBF9F8", marginTop: "0.5rem",
                  height: "5700px",
                  borderRadius: "1rem"
                }}
                scrolling="no"
                frameBorder="0"
                title="Panel"
                src={`https://app.powerbi.com/reportEmbed?reportId=8db7c08a-4b13-40d5-8326-9eb2395ed752&autoAuth=true&ctid=5a3c2a26-df13-4524-9df2-07d9a4d4a7d0&language=es-MX`}></iframe>
                <div
                style={{
                  width: "100%",
                  height: "40px",
                  background: "#FFF",
                  zIndex: "1000",
                  position: "relative",
                  marginTop: "-40px"
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>;

AnalyticsView.propTypes = {
  onClickExcel: PropTypes.func
};

export default AnalyticsView;