import React from "react";
import PropTypes from "prop-types";

const LoginAuth = () =>
  <div>

  </div>;

LoginAuth.propTypes = {};

export default LoginAuth;