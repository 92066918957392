import React from "react";
import PropTypes from "prop-types";
import styles from 'resources/styles/Login.module.css';
import cx from 'classnames';

const Login = ({ redirect_href }) =>

  <div className={styles.module}>
    <div className={styles.background}>

      <img
        className={styles.logo}
        src={require("resources/images/logo.png")}
        alt="Logo" />

      <div className={styles.dots} />
      <div className={cx(styles.container, 'animated zoomIn')}>

        <label className={styles.title}>Inicio de sesión<br /><span></span></label>
        <a href={redirect_href} className={styles.submit}>Iniciar sesión con Cuenta de Microsoft</a>

      </div>

    </div>
  </div>

Login.propTypes = {};

export default Login;