/* eslint-disable max-len */
import React from "react";
import { Formik, Field, Form } from "formik";
import PropTypes from "prop-types";
import { ModalRoute, ScriptTag } from "seed/helpers";
import Confirmation from "components/forms/Confirmation";
import { BrowserRouter, Route } from "react-router-dom";


const ConflictOfInterest = ({ departments = [], cities = [], user, users = [], error, currentYear,
  q3Individuals, q4Individuals, q5Individuals, q6Individuals, q7Individuals, addIndividual, removeIndividual,
  onChangeTextField, getColor, originalResponse, onChangeSelect, modifiedResponse, onSubmit }) =>
  <>
    <main id="content">
      <div
        class="position-fixed top-0 right-0 left-0 bg-img-hero"
        style={{
          height: "32rem",
          marginRight: "20px",
          backgroundImage: "url(/theme/svg/components/abstract-bg-4.svg)",
        }}
      >
        <figure class="position-absolute right-0 bottom-0 left-0">
          <svg
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1921 273"
          >
            <polygon fill="#00454d" points="0,273 1921,273 1921,0 " />
          </svg>
        </figure>
      </div>

      <div class="container py-5 py-sm-7">
        <a class="d-flex justify-content-center mb-5" href="index.html">
          <h1
            class="mt-2 display-4"
            style={{
              position: "absolute",
              zIndex: "3",
              color: "white",
              marginRight: "22.5rem",
            }}
          >
            Conflicto de Interés
          </h1>
          <img
            class="z-index-2 ml-10"
            src="/theme/svg/logos/logoCrz.png"
            alt="Logo"
            style={{ width: "8rem" }}
          />
        </a>

        <div class="row justify-content-center">
          <div class="col-md-10">
            <div class="card card-lg mb-5">
              <div class="card-body">
                <div class="text-center">
                  <div class="mb-5 mt-5">
                    <p class="display-4" style={{ fontSize: 35 }}>Conflicto de Interés {currentYear} (POL-17.FO-01)</p>
                  </div>
                  <div
                    style={{
                      color: "#41464b",
                      backgroundColor: "#f0f0f0",
                      borderColor: "#d3d6d8",
                      textAlign: "justify",
                    }}
                    class="alert mb-6 ml-6 mr-6 mt-3"
                    role="alert"
                  >
                    <br />
                    <p style={{ color: "black", fontStyle: "italic" }}>
                      En cumplimiento a nuestro Código de Conducta y Ética y la Política de Gestión de Conflicto de Interés, solicitamos tu apoyo para completar la siguiente encuesta que nos permitirá actualizar la base
                      de datos de relaciones que pueden ser percibidas como un posible conflicto de interés. La base de datos será administrada por el área de Control Interno de CRZ y se han implementado las medidas
                      de seguridad correspondientes para que tus datos estén protegidos de acuerdo con las políticas de seguridad de la información de la Firma.
                      Las respuestas que enviaste al llenar la última encuesta de detección de conflicto de interés <b>están precargadas</b> en esta plataforma, por lo que <b>solo deberás corroborar</b> si estos datos son correctos
                      o, en su caso, actualizarlos si se produjeron cambios. Si tu ingreso a la Firma es reciente o no respondiste dicha encuesta, por favor, llena cada campo para generar el histórico de tus respuestas.
                    </p>
                  </div>
                </div>

                <div class="row justify-content-center">
                  <div class="col-lg-11 text-center pl-6 pr-6 pb-8" style={{ backgroundColor: "white" }}>
                    <Formik
                      initialValues={originalResponse}
                      onSubmit={onSubmit}>
                      {({ values, setFieldValue }) =>
                        <Form>
                          <div class="mb-3">
                            {/* Q1 */}
                            <div class="form-group text-left">
                              <b>Nombre: </b>{user.last_name}<br /><br /><br />
                              <div>
                                <label class="input-label"><b>1.Indica el área en la que colaboras.</b></label>
                                <Field as="select" name="q1.id" value={modifiedResponse.q1.id} style={{ backgroundColor: getColor("q1") }}
                                  class="form-control mt-2" onChange={(event) => onChangeSelect(event)} required>
                                  <option value="">Selecciona una opción</option>
                                  {departments.map((e, idx) => <option key={idx} value={e.id}>{e.name}</option>)}
                                </Field>
                              </div>
                            </div>
                            <div class="col-12 my-4">
                              <hr style={{ position: "relative", border: "none", height: "1px", background: "#00454d00" }} />
                            </div>
                            {/* Q2 */}
                            <div class="form-group text-left">
                              <div>
                                <label class="input-label"><b>2.¿En qué oficina de CRZ laboras?</b></label>
                                <Field as="select" name="q2.id" value={modifiedResponse.q2.id} style={{ backgroundColor: getColor("q2") }}
                                  class="form-control mt-2" onChange={(event) => onChangeSelect(event)} required>
                                  <option value="">Selecciona una opción</option>
                                  {cities.map((e, idx) => <option key={idx} value={e.id}>{e.name}</option>)}
                                </Field>
                              </div>
                            </div>
                            <div class="col-12 my-4">
                              <hr style={{ position: "relative", border: "none", height: "1px", background: "#00454d00" }} />
                            </div>

                            {/* Q3 */}
                            <h4 class="text-left p-2" style={{ background: "#f0f0f0" }}>Clientes</h4>
                            <p class="font-italic text-left" style={{ fontSize: "0.95rem", color: "#4a4a4a" }}>Esta sección busca conocer cómo pueden afectar a las partes interesadas, las decisiones que CRZ tome. Para responder adecuadamente a todas las preguntas deberás considerar como
                              &quot;familiares&quot; a tus padres, hijo(as), tu cónyuge, abuelo(as), hermano(as), cuñado(as), tío(as), primo(as), sobrinos (as) o cualquier otra relación de parentesco, amistad o con tu círculo cercano que estimes relevante declarar si dicha(s) persona(s) desempeña un puesto o tienen algún cargo en alguna entidad pública u organización de las señaladas más adelante.</p>
                            <label class="input-label text-left mt-4 mb-2"> <b>3. Alguno de tus familiares trabaja en alguna organización para la que CRZ presta servicios?</b></label>
                            {q3Individuals.map((q3Individual, index) => (
                              <div class="row justify-content-center ml-4" key={"q3" + (index + 1)}>
                                {/* Q312 */}
                                <div class={"form-group text-left " + (q3Individual.name_of_familiar != "N/A" ? "col-6" : "col-12 mb-0")}>
                                  <label class="input-label mt-2"> Menciona el nombre completo de tu familiar
                                    <label class="pl-2">
                                      ( <input type="checkbox" checked={q3Individual.name_of_familiar == "N/A"}
                                        onChange={(event) => { onChangeTextField("q3", index, "name_of_familiar", event.target.checked ? "N/A" : "") }} />
                                      <i> No aplica</i> )
                                    </label>
                                  </label>
                                  <Field type="text" name={"q3." + (index + 1) + ".name_of_familiar"}
                                    class={"form-control mt-2 " + (q3Individual.name_of_familiar == "N/A" ? "d-none" : "")}
                                    maxLength="250" value={q3Individual.name_of_familiar}
                                    onChange={(event) => { onChangeTextField("q3", index, "name_of_familiar", event.target.value) }}
                                    style={{ backgroundColor: getColor("q3", index, "name_of_familiar") }} />
                                </div>
                                {/* Q314 */}
                                <div class={"form-group col-lg-6 text-left " + (q3Individual.name_of_familiar == "N/A" ? "d-none" : "")} >
                                  <label class="input-label mt-2"> Indica el parentesco de tu familiar</label>
                                  <Field type="text" name={"q3." + (index + 1) + ".relationship_of_familiar"}
                                    class="form-control mt-1" maxLength="250" value={q3Individual.relationship_of_familiar}
                                    onChange={(event) => { onChangeTextField("q3", index, "relationship_of_familiar", event.target.value) }}
                                    style={{ backgroundColor: getColor("q3", index, "relationship_of_familiar") }} />
                                </div>
                                {/* Q311 */}
                                <div class={"form-group col-lg-6 text-left " + (q3Individual.name_of_familiar == "N/A" ? "d-none" : "")} >
                                  <label class="input-label mt-1"> Indica el nombre del cliente</label>
                                  <Field type="text" name={"q3." + (index + 1) + ".entity_with_conflict"}
                                    class="form-control mt-2" maxLength="250" value={q3Individual.entity_with_conflict}
                                    onChange={(event) => { onChangeTextField("q3", index, "entity_with_conflict", event.target.value) }}
                                    style={{ backgroundColor: getColor("q3", index, "entity_with_conflict") }} />
                                </div>
                                {/* Q313 */}
                                <div class={"form-group col-lg-6 text-left " + (q3Individual.name_of_familiar == "N/A" ? "d-none" : "")} >
                                  <label class="input-label mt-1"> Indica el puesto que ocupa tu familiar</label>
                                  <Field type="text" name={"q3." + (index + 1) + ".position_of_familiar"}
                                    class="form-control mt-2" maxLength="250" value={q3Individual.position_of_familiar}
                                    onChange={(event) => { onChangeTextField("q3", index, "position_of_familiar", event.target.value) }}
                                    style={{ backgroundColor: getColor("q3", index, "position_of_familiar") }} />
                                </div>
                                <div class="col-12 text-left">
                                  <a
                                    class="btn btn-link mb-2"
                                    onClick={() => removeIndividual("q3", index)}
                                    style={{ color: "#007473" }}
                                  >
                                    <i class="fas fa-minus"></i> Quitar registro
                                  </a>
                                </div>

                                <div class="col-12">
                                  <hr style={{ position: "relative", border: "none", height: "0.5px", background: "black" }} />
                                </div>
                              </div>
                            ))}

                            <div class="text-left mb-1">
                              <a
                                class="btn btn-link"
                                onClick={() => addIndividual("q3")}
                                style={{ color: "#007473" }}
                              >
                                <i class="fas fa-plus"></i> Agregar registro
                              </a>
                            </div>

                            <div class="col-12 my-4">
                              <hr style={{ position: "relative", border: "none", height: "1px", background: "#00454d00" }} />
                            </div>

                            {/* Q4 */}
                            <h4 class="text-left p-2" style={{ background: "#f0f0f0" }}>Gobierno y partidos políticos</h4>
                            <p class="font-italic text-left" style={{ fontSize: "0.95rem", color: "#4a4a4a" }}>Esta sección permite conocer un poco más a las personas que conforman CRZ.</p>
                            <label class="input-label text-left mt-4 mb-2"><b>4. ¿Alguno de tus familiares trabaja en alguna entidad de gobierno o partido político?</b></label>
                            {
                              q4Individuals.map((q4Individual, index) => (
                                <div class="row justify-content-center ml-4" key={"q4" + (index + 1)}>
                                  {/* Q412 */}
                                  <div class={"form-group text-left " + (q4Individual.name_of_familiar != "N/A" ? "col-6" : "col-12 mb-0")}>
                                    <label class="input-label mt-2"> Menciona el nombre completo de tu familiar
                                      <label class="pl-2">
                                        ( <input type="checkbox" checked={q4Individual.name_of_familiar == "N/A"}
                                          onChange={(event) => { onChangeTextField("q4", index, "name_of_familiar", event.target.checked ? "N/A" : "") }} />
                                        <i> No aplica</i> )
                                      </label>
                                    </label>
                                    <Field type="text" name={"q4." + (index + 1) + ".name_of_familiar"}
                                      class={"form-control mt-2 " + (q4Individual.name_of_familiar == "N/A" ? "d-none" : "")}
                                      maxLength="250" value={q4Individual.name_of_familiar}
                                      onChange={(event) => { onChangeTextField("q4", index, "name_of_familiar", event.target.value) }}
                                      style={{ backgroundColor: getColor("q4", index, "name_of_familiar") }} />
                                  </div>
                                  {/* Q414 */}
                                  <div class={"form-group col-lg-6 text-left " + (q4Individual.name_of_familiar == "N/A" ? "d-none" : "")} >
                                    <label class="input-label mt-2"> Indica el parentesco de tu familiar</label>
                                    <Field type="text" name={"q4." + (index + 1) + ".relationship_of_familiar"}
                                      class="form-control mt-2" maxLength="250" value={q4Individual.relationship_of_familiar}
                                      onChange={(event) => { onChangeTextField("q4", index, "relationship_of_familiar", event.target.value) }}
                                      style={{ backgroundColor: getColor("q4", index, "relationship_of_familiar") }} />
                                  </div>
                                  {/* Q411 */}
                                  <div class={"form-group col-lg-6 text-left " + (q4Individual.name_of_familiar == "N/A" ? "d-none" : "")} >
                                    <label class="input-label mt-1"> Indica el nombre de la entidad gubernamental o partido político en el que labora tu familiar</label>
                                    <Field type="text" name={"q4." + (index + 1) + ".entity_with_conflict"}
                                      class="form-control mt-2" maxLength="250" value={q4Individual.entity_with_conflict}
                                      onChange={(event) => { onChangeTextField("q4", index, "entity_with_conflict", event.target.value) }}
                                      style={{ backgroundColor: getColor("q4", index, "entity_with_conflict") }} />
                                  </div>
                                  {/* Q413 */}
                                  <div class={"form-group col-lg-6 text-left " + (q4Individual.name_of_familiar == "N/A" ? "d-none" : "")} >
                                    <label class="input-label mt-2"> Indica el puesto que ocupa tu familiar</label>
                                    <Field type="text" name={"q4." + (index + 1) + ".position_of_familiar"}
                                      class="form-control mt-2" maxLength="250" value={q4Individual.position_of_familiar}
                                      onChange={(event) => { onChangeTextField("q4", index, "position_of_familiar", event.target.value) }}
                                      style={{ backgroundColor: getColor("q4", index, "position_of_familiar") }} />
                                  </div>
                                  <div class="col-12 text-left">
                                    <a
                                      class="btn btn-link mb-2"
                                      onClick={() => removeIndividual("q4", index)}
                                      style={{ color: "#007473" }}
                                    >
                                      <i class="fas fa-minus"></i> Quitar registro
                                    </a>
                                  </div>
                                  <div class="col-12">
                                    <hr style={{ position: "relative", border: "none", height: "0.5px", background: "black" }} />
                                  </div>
                                </div>
                              ))
                            }

                            <div class="text-left mb-1">
                              <a
                                class="btn btn-link"
                                onClick={() => addIndividual("q4")}
                                style={{ color: "#007473" }}
                              >
                                <i class="fas fa-plus"></i> Agregar registro
                              </a>
                            </div>

                            {/* q5 */}
                            <div class="col-12 my-4">
                              <hr style={{ position: "relative", border: "none", height: "1px", background: "#00454d00" }} />
                            </div>
                            <h4 class="text-left p-2" style={{ background: "#f0f0f0" }}>Firmas legales - contables - consultoras</h4>
                            <p class="font-italic text-left" style={{ fontSize: "0.95rem", color: "#4a4a4a" }}>Para responder adecuadamente a las siguientes preguntas deberás tomar en cuenta a despachos legales, contables,
                              consultoras u organizaciones semejantes cuyas áreas de práctica sean iguales o similares a las de CRZ.</p>
                            <label class="input-label text-left mt-4 mb-2"><b>5. ¿Alguno de tus familiares trabaja en una organización cuyas áreas de práctica sean iguales o similares a las de CRZ?</b></label>
                            {
                              q5Individuals.map((q5Individual, index) => (
                                <div class="row justify-content-center ml-4" key={"q5" + (index + 1)}>
                                  {/* Q512 */}
                                  <div class={"form-group text-left " + (q5Individual.name_of_familiar != "N/A" ? "col-6" : "col-12 mb-0")}>
                                    <label class="input-label mt-2">Menciona el nombre completo de tu familiar
                                      <label class="pl-2">
                                        ( <input type="checkbox" checked={q5Individual.name_of_familiar == "N/A"}
                                          onChange={(event) => { onChangeTextField("q5", index, "name_of_familiar", event.target.checked ? "N/A" : "") }} />
                                        <i> No aplica</i> )
                                      </label>
                                    </label>
                                    <Field type="text" name={"q5." + (index + 1) + ".name_of_familiar"}
                                      class={"form-control mt-2 " + (q5Individual.name_of_familiar == "N/A" ? "d-none" : "")}
                                      maxLength="250" value={q5Individual.name_of_familiar}
                                      onChange={(event) => { onChangeTextField("q5", index, "name_of_familiar", event.target.value) }}
                                      style={{ backgroundColor: getColor("q5", index, "name_of_familiar") }} />
                                  </div>
                                  {/* Q514 */}
                                  <div class={"form-group col-lg-6 text-left " + (q5Individual.name_of_familiar == "N/A" ? "d-none" : "")} >
                                    <label class="input-label mt-2">Indica el parentesco de tu familiar</label>
                                    <Field type="text" name={"q5." + (index + 1) + ".relationship_of_familiar"}
                                      class="form-control mt-2" maxLength="250" value={q5Individual.relationship_of_familiar}
                                      onChange={(event) => { onChangeTextField("q5", index, "relationship_of_familiar", event.target.value) }}
                                      style={{ backgroundColor: getColor("q5", index, "relationship_of_familiar") }} />
                                  </div>
                                  {/* Q511 */}
                                  <div class={"form-group col-lg-6 text-left " + (q5Individual.name_of_familiar == "N/A" ? "d-none" : "")} >
                                    <label class="input-label mt-1">Menciona la denominación de la firma o consultora</label>
                                    <Field type="text" name={"q5." + (index + 1) + ".entity_with_conflict"}
                                      class="form-control mt-2" maxLength="250" value={q5Individual.entity_with_conflict}
                                      onChange={(event) => { onChangeTextField("q5", index, "entity_with_conflict", event.target.value) }}
                                      style={{ backgroundColor: getColor("q5", index, "entity_with_conflict") }} />
                                  </div>
                                  {/* Q513 */}
                                  <div class={"form-group col-lg-6 text-left " + (q5Individual.name_of_familiar == "N/A" ? "d-none" : "")} >
                                    <label class="input-label mt-1"> Indica el puesto que ocupa tu familiar</label>
                                    <Field type="text" name={"q5." + (index + 1) + ".position_of_familiar"}
                                      class="form-control mt-2" maxLength="250" value={q5Individual.position_of_familiar}
                                      onChange={(event) => { onChangeTextField("q5", index, "position_of_familiar", event.target.value) }}
                                      style={{ backgroundColor: getColor("q5", index, "position_of_familiar") }} />
                                  </div>
                                  <div class="col-12 text-left">
                                    <a
                                      class="btn btn-link mb-2"
                                      onClick={() => removeIndividual("q5", index)}
                                      style={{ color: "#007473" }}
                                    >
                                      <i class="fas fa-minus"></i> Quitar registro
                                    </a>
                                  </div>
                                  <div class="col-12">
                                    <hr style={{ position: "relative", border: "none", height: "0.5px", background: "black" }} />
                                  </div>
                                </div>
                              ))
                            }

                            <div class="text-left mb-1">
                              <a
                                class="btn btn-link"
                                onClick={() => addIndividual("q5")}
                                style={{ color: "#007473" }}
                              >
                                <i class="fas fa-plus"></i> Agregar registro
                              </a>
                            </div>

                            {/* Q6 */}
                            <div class="col-12 my-4">
                              <hr style={{ position: "relative", border: "none", height: "1px", background: "#00454d00" }} />
                            </div>
                            <h4 class="text-left p-2" style={{ background: "#f0f0f0" }}>Aliados estratégicos y otras organizaciones</h4>
                            <p class="font-italic text-left" style={{ fontSize: "0.95rem", color: "#4a4a4a" }}>En esta sección se han considerado como &quot;aliados estratégicos&quot; a las organizaciones que se relacionan con CRZ
                              debido a la labor pro bono y de responsabilidad social que realizamos, así como cualquier otra que no pueda ser
                              clasificada en las secciones anteriores.</p>
                            <label class="input-label text-left mt-4 mb-2"><b>6. ¿Alguno de tus familiares trabaja con aliados estratégicos u organizaciones que se relacionen
                              con CRZ derivado de sus actividades de responsabilidad social o pro bono?</b></label>
                            {
                              q6Individuals.map((q6Individual, index) => (
                                <div class="row justify-content-center ml-4" key={"q6" + (index + 1)}>
                                  {/* Q612 */}
                                  <div class={"form-group text-left " + (q6Individual.name_of_familiar != "N/A" ? "col-6" : "col-12 mb-0")}>
                                    <label class="input-label mt-2"> Menciona el nombre completo de tu familiar
                                      <label class="pl-2">
                                        ( <input type="checkbox" checked={q6Individual.name_of_familiar == "N/A"}
                                          onChange={(event) => { onChangeTextField("q6", index, "name_of_familiar", event.target.checked ? "N/A" : "") }} />
                                        <i> No aplica</i> )
                                      </label>
                                    </label>
                                    <Field type="text" name={"q6." + (index + 1) + ".name_of_familiar"}
                                      class={"form-control mt-2 " + (q6Individual.name_of_familiar == "N/A" ? "d-none" : "")}
                                      maxLength="250" value={q6Individual.name_of_familiar}
                                      onChange={(event) => { onChangeTextField("q6", index, "name_of_familiar", event.target.value) }}
                                      style={{ backgroundColor: getColor("q6", index, "name_of_familiar") }} />
                                  </div>
                                  {/* Q614 */}
                                  <div class={"form-group col-lg-6 text-left " + (q6Individual.name_of_familiar == "N/A" ? "d-none" : "")} >
                                    <label class="input-label mt-2"> Indica el parentesco de tu familiar</label>
                                    <Field type="text" name={"q6." + (index + 1) + ".relationship_of_familiar"}
                                      class="form-control mt-2" maxLength="250" value={q6Individual.relationship_of_familiar}
                                      onChange={(event) => { onChangeTextField("q6", index, "relationship_of_familiar", event.target.value) }}
                                      style={{ backgroundColor: getColor("q6", index, "relationship_of_familiar") }} />
                                  </div>
                                  {/* Q611 */}
                                  <div class={"form-group col-lg-6 text-left " + (q6Individual.name_of_familiar == "N/A" ? "d-none" : "")} >
                                    <label class="input-label mt-1"> Indica el nombre del aliado estratégico u organización</label>
                                    <Field type="text" name={"q6." + (index + 1) + ".entity_with_conflict"}
                                      class="form-control mt-2" maxLength="250" value={q6Individual.entity_with_conflict}
                                      onChange={(event) => { onChangeTextField("q6", index, "entity_with_conflict", event.target.value) }}
                                      style={{ backgroundColor: getColor("q6", index, "entity_with_conflict") }} />
                                  </div>
                                  {/* Q613 */}
                                  <div class={"form-group col-lg-6 text-left " + (q6Individual.name_of_familiar == "N/A" ? "d-none" : "")} >
                                    <label class="input-label mt-1"> Indica el puesto que ocupa tu familiar</label>
                                    <Field type="text" name={"q6." + (index + 1) + ".position_of_familiar"}
                                      class="form-control mt-2" maxLength="250" value={q6Individual.position_of_familiar}
                                      onChange={(event) => { onChangeTextField("q6", index, "position_of_familiar", event.target.value) }}
                                      style={{ backgroundColor: getColor("q6", index, "position_of_familiar") }} />
                                  </div>
                                  <div class="col-12 text-left">
                                    <a
                                      class="btn btn-link mb-2"
                                      onClick={() => removeIndividual("q6", index)}
                                      style={{ color: "#007473" }}
                                    >
                                      <i class="fas fa-minus"></i> Quitar registro
                                    </a>
                                  </div>
                                  <div class="col-12">
                                    <hr style={{ position: "relative", border: "none", height: "0.5px", background: "black" }} />
                                  </div>
                                </div>
                              ))
                            }

                            <div class="text-left mb-1">
                              <a
                                class="btn btn-link"
                                onClick={() => addIndividual("q6")}
                                style={{ color: "#007473" }}
                              >
                                <i class="fas fa-plus"></i> Agregar registro
                              </a>
                            </div>


                            {/* Q7 */}
                            <div class="col-12 my-4">
                              <hr style={{ position: "relative", border: "none", height: "1px", background: "#00454d00" }} />
                            </div>
                            <h4 class="text-left p-2" style={{ background: "#f0f0f0" }}>Proveedores</h4>
                            <label class="input-label text-left mt-4 mb-2"><b>7. ¿Alguno de tus familiares trabaja en una empresa que sea proveedora de la Firma?</b></label>
                            {
                              q7Individuals.map((q7Individual, index) => (
                                <div class="row justify-content-center ml-4" key={"q7" + (index + 1)}>
                                  {/* Q712 */}
                                  <div class={"form-group text-left " + (q7Individual.name_of_familiar != "N/A" ? "col-6" : "col-12 mb-0")}>
                                    <label class="input-label mt-2"> Menciona el nombre completo de tu familiar
                                      <label class="pl-2">
                                        ( <input type="checkbox" checked={q7Individual.name_of_familiar == "N/A"}
                                          onChange={(event) => { onChangeTextField("q7", index, "name_of_familiar", event.target.checked ? "N/A" : "") }} />
                                        <i> No aplica</i> )
                                      </label>
                                    </label>
                                    <Field type="text" name={"q7." + (index + 1) + ".name_of_familiar"}
                                      class={"form-control mt-2 " + (q7Individual.name_of_familiar == "N/A" ? "d-none" : "")}
                                      maxLength="250" value={q7Individual.name_of_familiar}
                                      onChange={(event) => { onChangeTextField("q7", index, "name_of_familiar", event.target.value) }}
                                      style={{ backgroundColor: getColor("q7", index, "name_of_familiar") }} />
                                  </div>
                                  {/* Q714 */}
                                  <div class={"form-group col-lg-6 text-left " + (q7Individual.name_of_familiar == "N/A" ? "d-none" : "")} >
                                    <label class="input-label mt-2"> Indica el parentesco de tu familiar</label>
                                    <Field type="text" name={"q7." + (index + 1) + ".relationship_of_familiar"}
                                      class="form-control mt-2" maxLength="250" value={q7Individual.relationship_of_familiar}
                                      onChange={(event) => { onChangeTextField("q7", index, "relationship_of_familiar", event.target.value) }}
                                      style={{ backgroundColor: getColor("q7", index, "relationship_of_familiar") }} />
                                  </div>
                                  {/* Q711 */}
                                  <div class={"form-group col-lg-6 text-left " + (q7Individual.name_of_familiar == "N/A" ? "d-none" : "")} >
                                    <label class="input-label mt-1"> Indica el nombre del proveedor</label>
                                    <Field type="text" name={"q7." + (index + 1) + ".entity_with_conflict"}
                                      class="form-control mt-2" maxLength="250" value={q7Individual.entity_with_conflict}
                                      onChange={(event) => { onChangeTextField("q7", index, "entity_with_conflict", event.target.value) }}
                                      style={{ backgroundColor: getColor("q7", index, "entity_with_conflict") }} />
                                  </div>
                                  {/* Q713 */}
                                  <div class={"form-group col-lg-6 text-left " + (q7Individual.name_of_familiar == "N/A" ? "d-none" : "")} >
                                    <label class="input-label mt-1"> Indica el puesto que ocupa tu familiar</label>
                                    <Field type="text" name={"q7." + (index + 1) + ".position_of_familiar"}
                                      class="form-control mt-2" maxLength="250" value={q7Individual.position_of_familiar}
                                      onChange={(event) => { onChangeTextField("q7", index, "position_of_familiar", event.target.value) }}
                                      style={{ backgroundColor: getColor("q7", index, "position_of_familiar") }} />
                                  </div>
                                  <div class="col-12 text-left">
                                    <a
                                      class="btn btn-link mb-2"
                                      onClick={() => removeIndividual("q7", index)}
                                      style={{ color: "#007473" }}
                                    >
                                      <i class="fas fa-minus"></i> Quitar registro
                                    </a>
                                  </div>
                                  <div class="col-12">
                                    <hr style={{ position: "relative", border: "none", height: "0.5px", background: "black" }} />
                                  </div>
                                </div>
                              ))
                            }

                            <div class="text-left mb-1">
                              <a
                                class="btn btn-link"
                                onClick={() => addIndividual("q7")}
                                style={{ color: "#007473" }}
                              >
                                <i class="fas fa-plus"></i> Agregar registro
                              </a>
                            </div>


                            <div class="col-12 my-4">
                              <hr style={{ position: "relative", border: "none", height: "1px", background: "#00454d00" }} />
                            </div>

                            {/* Q8 */}
                            <div class="form-group text-left">
                              <label class="input-label mb-1"><b>8.Por último, ¿la encuesta te pareció clara y fácil de responder?</b></label>
                              <p class="text-left font-italic mb-3 mt-1" style={{ fontSize: "0.95rem" }}>Considera al número 1 como la calificación más baja por la poca claridad y alto grado de dificultad de la encuesta y al número 3 como la
                                calificación más alta, en virtud de la facilidad y claridad de este ejercicio  </p>
                              <Field as="select" name="q8" value={modifiedResponse.q8}
                                class="form-control mt-2" onChange={(event) => onChangeSelect(event)} required>
                                <option value="">Selecciona una opción</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                              </Field>
                            </div>
                          </div>
                          {error ? <div class="alert alert-soft-danger">{error}</div> : null}
                          <button type="submit" class="btn btn-block btn-primary">Enviar</button>
                        </Form>}
                    </Formik>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <ScriptTag
      content={`
        $(document).ready(function() {
          $("#start, #end").on("click", function(e) {
            e.preventDefault()
            $(this).prop("disabled", !$(this).prop("disabled"))
            $(this).siblings().prop("disabled", !$(this).siblings().prop("disabled"))
          })
        })
      `}
    />
    <ModalRoute
      path="/form/confirmation"
      width={800}
      height={600}
      component={Confirmation} />
  </>

ConflictOfInterest.propTypes = {
  departments: PropTypes.array,
  cities: PropTypes.array,
  user: PropTypes.object,
  users: PropTypes.array,
  error: PropTypes.string,
  currentYear: PropTypes.number,
  getColor: PropTypes.func,
  q3Individuals: PropTypes.array,
  q4Individuals: PropTypes.array,
  q5Individuals: PropTypes.array,
  q6Individuals: PropTypes.array,
  q7Individuals: PropTypes.array,
  addIndividual: PropTypes.func,
  removeIndividual: PropTypes.func,
  onChangeTextField: PropTypes.func,
  originalResponse: PropTypes.object,
  onChangeSelect: PropTypes.func,
  modifiedResponse: PropTypes.object,
  onSubmit: PropTypes.func
};

export default ConflictOfInterest;