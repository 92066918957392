import React from "react";
import PropTypes from "prop-types";
import View from "components/auth/LoginAuth.view";
import { useLocation } from "react-router";

function LoginAuth(props) {

  let { search } = useLocation();
  let query = new URLSearchParams(search);
  const status = query.get("status");
  const token = query.get("token");
  const id = query.get("id");

  if (status == "OK"){
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('id', id);
    sessionStorage.setItem('role', 'admin');
    const redirectUrl = sessionStorage.getItem("loginRedirect") ?? "/form";
    sessionStorage.removeItem("loginRedirect")
    window.location.replace(redirectUrl);
    
  }else return <div>Ha ocurrido un error iniciando sesión</div>;


  return <View />;
}

LoginAuth.propTypes = {};

export default LoginAuth;