/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";

const Confirmation = ({ getColor, onSubmit, response, department, city, q3Individuals, q4Individuals, q5Individuals,
  q6Individuals, q7Individuals }) =>
  <div>
    <div class="card pr-2 pb-2 pt-2">
      <div class="justify-content-center p-3">
        <div class="col-lg-11 mb-4 px-5">
          <h4 class="ml mt-3">Confirmación de respuestas</h4>
          <i><p class="mt-2 mb-3"><label class="mr-2" style={{ background: "#F0A000", width: "15px", height: "15px" }}></label> Respuestas modificadas respecto al año pasado </p></i>
          <div class="row">
            {/* Q1 */}
            <div class="col-12 pt-2" >
              <label class="input-label text-left">1. Indica el área en la que colaboras.</label>
              <div class="py-2" style={{ backgroundColor: getColor("q1") }}>
                Tu respuesta:<b> {department.name}</b>
              </div>
            </div>
            <div class="col-12">
              <hr style={{ position: "relative", border: "none", height: "1px", background: "rgba(0,0,0,0.15)" }} />
            </div>
            {/* Q2 */}
            <div class="col-12 pt-3">
              <label class="input-label text-left">2. ¿En qué oficina de CRZ laboras?</label>
              <div class="py-2" style={{ backgroundColor: getColor("q2") }}>
                Tu respuesta:<b> {city.name}</b>
              </div>
            </div>
            <div class="col-12">
              <hr style={{ position: "relative", border: "none", height: "1px", background: "rgba(0,0,0,0.15)" }} />
            </div>

            {/* Q3 */}
            <div class="col-12">
              <h4 class="text-left">Clientes</h4>
              <label class="input-label text-left mb-1">3. ¿Alguno de tus familiares trabaja en alguna organización para la que CRZ presta servicios?</label>
            </div>

            {
              q3Individuals.length > 0 ?
                <table class="table table-bordered mt-4 mb-7">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Nombre</th>
                      <th scope="col">Parentesco</th>
                      <th scope="col">Cliente</th>
                      <th scope="col">Puesto</th>
                    </tr>
                  </thead>
                  <tbody>
                    {q3Individuals.map((q3Individual, index) => (
                      <tr key={"q3" + (index + 1)}>
                        <td>{index + 1}</td>
                        <td style={{ backgroundColor: getColor("q3", index, "name_of_familiar") }}>{q3Individual.name_of_familiar}</td>
                        <td style={{ backgroundColor: getColor("q3", index, "relationship_of_familiar") }}>{q3Individual.relationship_of_familiar}</td>
                        <td style={{ backgroundColor: getColor("q3", index, "entity_with_conflict") }}>{q3Individual.entity_with_conflict}</td>
                        <td style={{ backgroundColor: getColor("q3", index, "position_of_familiar") }}>{q3Individual.position_of_familiar}</td>
                      </tr>
                    ))}

                  </tbody>
                </table> : <div class="col-12 mb-7">No se agregaron clientes con los que se tenga conflicto de interés</div>
            }

            {/* Q4 */}
            <div class="col-12">
              <h4 class="text-left">Gobierno y partidos políticos</h4>
              <label class="input-label text-left">4. ¿Alguno de tus familiares trabaja en alguna entidad de gobierno o partido político?</label>
            </div>
            {
              q4Individuals.length > 0 ?
                <table class="table table-bordered mt-4 mb-7">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Nombre</th>
                      <th scope="col">Parentesco</th>
                      <th scope="col">Entidad/ Partido político</th>
                      <th scope="col">Puesto</th>
                    </tr>
                  </thead>
                  <tbody>
                    {q4Individuals.map((q4Individual, index) => (
                      <tr key={"q4" + (index + 1)}>
                        <td>{index + 1}</td>
                        <td style={{ backgroundColor: getColor("q4", index, "name_of_familiar") }}>{q4Individual.name_of_familiar}</td>
                        <td style={{ backgroundColor: getColor("q4", index, "relationship_of_familiar") }}>{q4Individual.relationship_of_familiar}</td>
                        <td style={{ backgroundColor: getColor("q4", index, "entity_with_conflict") }}>{q4Individual.entity_with_conflict}</td>
                        <td style={{ backgroundColor: getColor("q4", index, "position_of_familiar") }}>{q4Individual.position_of_familiar}</td>
                      </tr>
                    ))}

                  </tbody>
                </table> : <div class="col-12 mb-7">No se agregaron entidades gubernamentales o partidos políticos con los que se tenga conflicto de interés</div>
            }
            {/* Q5 */}
            <div class="col-12">
              <h4 class="text-left">Firmas legales - contables - consultoras</h4>
              <label class="input-label text-left">5. ¿Alguno de tus familiares trabaja en una organización cuyas áreas de práctica sean iguales o similares a las de CRZ?</label>
            </div>
            {
              q5Individuals.length > 0 ?
                <table class="table table-bordered mt-4 mb-7">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Nombre</th>
                      <th scope="col">Parentesco</th>
                      <th scope="col">Firma o consultoría</th>
                      <th scope="col">Puesto</th>
                    </tr>
                  </thead>
                  <tbody>
                    {q5Individuals.map((q5Individual, index) => (
                      <tr key={"q5" + (index + 1)}>
                        <td>{index + 1}</td>
                        <td style={{ backgroundColor: getColor("q5", index, "name_of_familiar") }}>{q5Individual.name_of_familiar}</td>
                        <td style={{ backgroundColor: getColor("q5", index, "relationship_of_familiar") }}>{q5Individual.relationship_of_familiar}</td>
                        <td style={{ backgroundColor: getColor("q5", index, "entity_with_conflict") }}>{q5Individual.entity_with_conflict}</td>
                        <td style={{ backgroundColor: getColor("q5", index, "position_of_familiar") }}>{q5Individual.position_of_familiar}</td>
                      </tr>
                    ))}

                  </tbody>
                </table> : <div class="col-12 mb-7">No se agregaron organizaciones con prácticas semejantes o similares a las de CRZ con las que se tenga conflicto de interés</div>
            }

            {/* Q6 */}
            <div class="col-12">
              <h4 class="text-left">Aliados estratégicos y otras organizaciones</h4>
              <label class="input-label text-left">6. ¿Alguno de tus familiares trabaja con aliados estratégicos u organizaciones que se relacionen
                con CRZ derivado de sus actividades de responsabilidad social o pro bono?</label>
            </div>
            {
              q6Individuals.length > 0 ?
                <table class="table table-bordered mt-4 mb-7">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Nombre</th>
                      <th scope="col">Parentesco</th>
                      <th scope="col">Aliado/ Organización</th>
                      <th scope="col">Puesto</th>
                    </tr>
                  </thead>
                  <tbody>
                    {q6Individuals.map((q6Individual, index) => (
                      <tr key={"q6" + (index + 1)}>
                        <td>{index + 1}</td>
                        <td style={{ backgroundColor: getColor("q6", index, "name_of_familiar") }}>{q6Individual.name_of_familiar}</td>
                        <td style={{ backgroundColor: getColor("q6", index, "relationship_of_familiar") }}>{q6Individual.relationship_of_familiar}</td>
                        <td style={{ backgroundColor: getColor("q6", index, "entity_with_conflict") }}>{q6Individual.entity_with_conflict}</td>
                        <td style={{ backgroundColor: getColor("q6", index, "position_of_familiar") }}>{q6Individual.position_of_familiar}</td>
                      </tr>
                    ))}

                  </tbody>
                </table>
                : <div class="col-12 mb-7">No se agregaron aliados estratégicos con los que se tenga conflicto de interés</div>
            }

            {/* Q7 */}
            <div class="col-12">
              <h4 class="text-left">Proveedores</h4>
              <label class="input-label text-left">7.¿Alguno de tus familiares trabaja en una empresa que sea proveedora de la Firma?</label>
            </div>
            {
              q7Individuals.length > 0 ?
                <table class="table table-bordered mt-4 mb-7">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Nombre</th>
                      <th scope="col">Parentesco</th>
                      <th scope="col">Proveedor</th>
                      <th scope="col">Puesto</th>
                    </tr>
                  </thead>
                  <tbody>
                    {q7Individuals.map((q7Individual, index) => (
                      <tr key={"q7" + (index + 1)}>
                        <td>{index + 1}</td>
                        <td style={{ backgroundColor: getColor("q7", index, "name_of_familiar") }}>{q7Individual.name_of_familiar}</td>
                        <td style={{ backgroundColor: getColor("q7", index, "relationship_of_familiar") }}>{q7Individual.relationship_of_familiar}</td>
                        <td style={{ backgroundColor: getColor("q7", index, "entity_with_conflict") }}>{q7Individual.entity_with_conflict}</td>
                        <td style={{ backgroundColor: getColor("q7", index, "position_of_familiar") }}>{q7Individual.position_of_familiar}</td>
                      </tr>
                    ))}

                  </tbody>
                </table>
                : <div class="col-12 mb-7">No se agregaron proveedores con los que se tenga conflicto de interés</div>
            }
            {/* Q8 */}
            <div class="col-12 pt-3">
              <label class="input-label"><b>8.</b> Por último, ¿la encuesta te pareció clara y fácil de responder?</label>
              <p class="text-left font-italic mb-3">Considera al número 1 como la calificación más baja por la poca claridad y alto grado de dificultad de la encuesta y al número 3 como la
                calificación más alta, en virtud de la facilidad y claridad de este ejercicio  </p>
              <div class="py-2">
                Tu respuesta:<b> {response.q8}</b>
              </div>
            </div>
          </div>
          <div class="row justify-content-center pt-6">
            <button type="button" class="btn btn-block btn-success col-10 font-weight-bold mt-2 mb-2" onClick={() => { onSubmit() }}>Confirmo el contenido de las respuestas del cuestionario</button>
          </div>
        </div>
      </div>
    </div>
  </div>;

Confirmation.propTypes = {
  response: PropTypes.object,
  getColor: PropTypes.func,
  onSubmit: PropTypes.func,
  getVisibility: PropTypes.func,
  department: PropTypes.object,
  city: PropTypes.object,
  q3Individuals: PropTypes.array,
  q4Individuals: PropTypes.array,
  q5Individuals: PropTypes.array,
  q6Individuals: PropTypes.array,
  q7Individuals: PropTypes.array
};

export default Confirmation;