import React, { useState } from "react";
import PropTypes from "prop-types";
import View from "components/forms/Confirmation.view";
import { useSave, useSet } from "seed/gql";
import { SAVE_RESPONSE, SET_RESPONSE } from "seed/gql/queries";
import { useGet, usePost, usePut } from "seed/api";
import { Loading } from "seed/helpers";
import { useQuery } from "@apollo/react-hooks";

function Confirmation({ onCompleted = () => null }) {
  const qUsers = useGet("/users", {});
  const qDepartments = useGet("/departments", {});
  const qCities = useGet("/cities", {});

  const [previousQ3Individuals, setPreviousQ3Individuals] = useState([]);
  const [previousQ4Individuals, setPreviousQ4Individuals] = useState([]);
  const [previousQ5Individuals, setPreviousQ5Individuals] = useState([]);
  const [previousQ6Individuals, setPreviousQ6Individuals] = useState([]);
  const [previousQ7Individuals, setPreviousQ7Individuals] = useState([]);

  const [q3Individuals, setQ3Individuals] = useState([]);
  const [q4Individuals, setQ4Individuals] = useState([]);
  const [q5Individuals, setQ5Individuals] = useState([]);
  const [q6Individuals, setQ6Individuals] = useState([]);
  const [q7Individuals, setQ7Individuals] = useState([]);

  const [showQRelationships, setShowQRelationShips] = useState([false, false, false, false, false]);

  const [isFirstResponse, setIsFirstResponse] = useState(false);

  const userId = sessionStorage.getItem("id");

  if (userId == null) {
    window.location.replace("/login");
  }

  const changeShowRelationships = (index) => {
    let temp = [...showQRelationships];

    temp[index] = !temp[index];

    setShowQRelationShips(temp);
  }

  const [previousResponse, setPreviousResponse] = useState({});
  const [modifiedResponse, setModifiedResponse] = useState({});

  const [callSendEmail, reqSendEmail] = usePost('/responses/send_email_new_response', {
    onCompleted: () => {
      window.location.replace("/completed_response")
    }
  })

  const qPrevResponses = useGet("/users/" + userId + "/get_last_response", {}, {
    onCompleted: (prevResponse) => {
      if ("is_first_response" in prevResponse) {
        setIsFirstResponse(prevResponse.is_first_response)
      }
      let temp = prevResponse.last_response;
      temp["q1"] = { "id": temp["q1_id"] }
      temp["q2"] = { "id": temp["q2_id"] }
      setPreviousResponse(temp)
      setPreviousQ3Individuals(prevResponse.relationships.q3_relationships);
      setPreviousQ4Individuals(prevResponse.relationships.q4_relationships);
      setPreviousQ5Individuals(prevResponse.relationships.q5_relationships);
      setPreviousQ6Individuals(prevResponse.relationships.q6_relationships);
      setPreviousQ7Individuals(prevResponse.relationships.q7_relationships);
    }
  });

  const qLastDraft = useGet("/users/" + userId + "/get_last_draft", {}, {
    onCompleted: (response) => {
      let temp = response.last_draft;
      temp["q1"] = { "id": temp["q1_id"] }
      temp["q2"] = { "id": temp["q2_id"] }

      setModifiedResponse(temp);
      setQ3Individuals(response.relationships.q3_relationships);
      setQ4Individuals(response.relationships.q4_relationships);
      setQ5Individuals(response.relationships.q5_relationships);
      setQ6Individuals(response.relationships.q6_relationships);
      setQ7Individuals(response.relationships.q7_relationships);
    }
  });

  const [callSet, qSet] = useSet(SET_RESPONSE, {
    onCompleted: (data) => {

      callSendEmail({
        response: data.setResponse.response.id
      })
      // window.location.replace("/completed_response")
    }

  });

  if (qUsers.loading || qDepartments.loading || qCities.loading || qLastDraft.loading || qPrevResponses.loading ||
    qSet.loading || reqSendEmail.loading)
    return <Loading />
  if (qUsers.error || qDepartments.error || qCities.error || qLastDraft.error || qPrevResponses.error ||
    qSet.error || reqSendEmail.error)
    return "Ha ocurrido un error, prueba de nuevo"

  const departments = qDepartments.data;
  const cities = qCities.data;
  const users = qUsers.data;

  let draft = qLastDraft.data.last_draft ? qLastDraft.data.last_draft : {};

  draft["q1"] = { "id": draft["q1_id"] }
  draft["q2"] = { "id": draft["q2_id"] }

  let currentYear = new Date().getFullYear();
  const date = new Date();

  const getQIndividuals = (q) => {
    switch (q) {
      case "q3":
        return q3Individuals;

      case "q4":
        return q4Individuals;

      case "q5":
        return q5Individuals;

      case "q6":
        return q6Individuals;

      case "q7":
        return q7Individuals;

      default:
        break;
    }
  }

  const getColor = (q, index, key) => {
    let previuousElements;

    switch (q) {
      case "q1":

        if (!isFirstResponse && draft[q]["id"] != previousResponse[q]["id"]) {
          return "#FAD070"

        } else {
          return ""
        }

      case "q2":
        if (!isFirstResponse && draft[q]["id"] != previousResponse[q]["id"]) {
          return "#FAD070"

        } else {
          return ""
        }

      case "q8":
        if (!isFirstResponse && draft[q] != previousResponse[q]) {
          return "#FAD070"

        } else {
          return ""
        }

      case "q3":
        previuousElements = previousQ3Individuals;
        break;

      case "q4":
        previuousElements = previousQ4Individuals;
        break;

      case "q5":
        previuousElements = previousQ5Individuals;
        break;

      case "q6":
        previuousElements = previousQ6Individuals;
        break;

      case "q7":
        previuousElements = previousQ7Individuals;
        break;

      default:
        break;
    }

    let currentElements = getQIndividuals(q);

    const currentElement = currentElements[index];

    let foundElement = false;

    for (let i = 0; i < previuousElements.length; i++) {
      const previuousElement = previuousElements[i];

      if (previuousElement.name_of_familiar == currentElement.name_of_familiar) {
        foundElement = true;
        if (previuousElement[key] != currentElement[key]) {
          return "#FAD070"
        }
      }

    }

    if (!foundElement && !isFirstResponse) {
      return "#FAD070"

    } else {
      return "";

    }

  }

  const onSubmit = () => {

    let result = {
      id: draft.id,
      status: "COMPLETED"
    }

    callSet(result);
  }

  const getCityById = (cityId) => cities.find(city => city.id == cityId)

  const getDepartmentById = (departmentId) => departments.find(department => department.id == departmentId)

  const department = getDepartmentById(modifiedResponse["q1"]["id"]);
  const city = getCityById(modifiedResponse["q2"]["id"]);


  return <View
    getColor={getColor}
    onSubmit={onSubmit}
    response={draft}
    department={department}
    city={city}
    q3Individuals={q3Individuals}
    q4Individuals={q4Individuals}
    q5Individuals={q5Individuals}
    q6Individuals={q6Individuals}
    q7Individuals={q7Individuals}
    changeShowRelationships={changeShowRelationships}
    showQRelationships={showQRelationships}
  />;
}

Confirmation.propTypes = {
  onCompleted: PropTypes.func
};

export default Confirmation;