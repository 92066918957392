/*
__Seed builder__
  AUTO_GENERATED (Read only)
  Modify via builder
*/

export const SINGULARS =
{
  "appInfos": "appInfo",
  "appInfo": "appInfo",
  "cities": "city",
  "city": "city",
  "departments": "department",
  "department": "department",
  "relationships": "relationship",
  "relationship": "relationship",
  "responses": "response",
  "response": "response",
  "users": "user",
  "user": "user",
};